<template>
    <div style="height: 100%">
        <LeftNavBar
            :title="selectedAppInitial"
            :drag="false"
            :hasChild="false"
            :items="sidebarItems"
            @opened="selectEmailType"
            :selectedValue="smsType"
        />
    </div>
</template>

<script>
import LeftNavBar from "bh-mod/components/common/LeftNavBar";
export default {
    components: {LeftNavBar },
    name: "menu-left",
    data() {
        return {
            openID: "",
        };
    },
    computed: {
		selectedAppInitial() {
            return (
                (this.$store.state.theApp &&
                    this.$store.state.theApp.media &&
                    this.$store.state.theApp.media.initial) ||
                "BSB"
            );
        },
        sidebarItems() {
            let items = [
                {
                    label: "Drafts",
                    value: "Drafts",
                    iconUrl: "",
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    label: "Scheduled",
                    value: "Scheduled",
                    iconUrl: "",
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    label: "Processing",
                    value: "Processing",
                    iconUrl: "",
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    label: "Sent",
                    value: "Sent",
                    iconUrl: "",
                    loading: false,
                    options: [],
                    children: [],
                },
                {
                    divider: true,
                },
                {
                    label: "Settings",
                    value: "Settings",
                    iconUrl: require("@/assets/settings.svg"),
                    loading: false,
                    options: [],
                    children: [],
                },
            ];
            return items;
        },
        smsType() {
            return this.$store.state.sms.selectedTab;
        },
    },
    methods: {
        selectEmailType(data) {
            if (data.value && this.smsType != data.value) {
                if (data.value === "Settings" && this.$route.path !== "/settings") {
                    this.$router.push(`/settings`);
                    return this.$store.commit("SMS_TYPE", data);
                } else if (this.$route.path !== "/") {
                    this.$router.push(`/`);
                    return this.$store.commit("SMS_TYPE", data);
                } else {
                    this.$store.commit("SMS_TYPE", data);
                }
            }
            return;
        }
    },
};
</script>
