import Vue from "vue";
import Vuex from "vuex";
import { $api, arrToObj, setProp, deleteProp } from "bh-mod";
Vue.use(Vuex);

export default {
	state: {
		selectedTab: "Sent",
		loadingPage: false,
		broadcasts: {},
		getStarted: false,
		allSettings: {},
		dom: { w: document.body.clientWidth, h: document.body.clientHeight },
		activePhoneNumbers: []
	},
	mutations: {
		SMS_TYPE(state, data) {
			state.selectedTab = data.value;
		},
		LOAD_PAGE: (state, status = !state.loadingPage) =>
			(state.loadingPage = status),
		SET_APPDATA(state, data) {
			if (!data) data = {};
			let { smsBroadcasts = [], sendingPhoneNumbers = [] } = data;
			smsBroadcasts = smsBroadcasts.filter(s => s.sentAt)
			state.broadcasts = arrToObj(smsBroadcasts);
			state.activePhoneNumbers = sendingPhoneNumbers
		},
		SET_SETTINGS(state, data) {
			let temp = data;

			if (data.userApp == null) {
				temp.userApp = {
					options: {
						seenIntro: false,
						sms: [],
					},
				};
			}

			if (data.app == null) {
				temp.app = {
					options: {},
				};
			}
			state.allSettings = temp;
		},
		GET_STARTED: (state) => {
			state.getStarted = true;
		},
		UPDATE_BROADCAST(state, broadcasts) {
			state.broadcasts = arrToObj(broadcasts);
		},
		VIEWPORT: (state, w) => (state.dom = w),
		SET_PHONE_NUMBER: (state, data) => (state.activePhoneNumbers = data),
		SET_PROP: (state, { where, what, del = false, update = false }) => {
			if (del) return deleteProp(state, where);
			if (typeof where === "string") where = where.split(".");
			state.newStoreTime = Date.now();
			setProp(state, where, what);
		},
	},
	actions: {
		SET_APPDATA: ({ commit }, data) => {
			commit("SET_APPDATA", data)
		},

		FETCH_PHONE_NUMBER: async ({ commit }) => {
			try {
				const { data } = await $api.get(`sending-phone-numbers/:instance`);
				commit('SET_PHONE_NUMBER', data)
			} catch (error) {
				console.error(`An error occurred while fetching instance phone number.`, error);
			}
		}
	},
	getters: {},
};
