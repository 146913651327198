import Vue from 'vue'
import Router from 'vue-router'
import AppLayout from 'bh-mod/layouts/App'
import Splash from '@/layouts/Splash'
import store from '@/store'
import { VueAppMiddleware } from 'bh-mod'
let hiveRequested = false

Vue.use(Router)

const router = new Router({
	base: process.env.BASE_URL,
	mode: 'history',
	scrollBehavior() {
		return { x: 0, y: 0 }
	},
	routes: [
		{
			path: '/',
			redirect: '/v3',
			component: AppLayout,
			meta: { instanceRequired: true, auth: true, hidden: true, },
			children: [
				// Dashboards
				{
					path: '/',
					meta: {
						title: 'SMS & Broadcasts',
						sidebar: true,
						dataRequired: true,
					},
					component: () =>
						import('./views/app'),
				},
				{
					path: '/new',
					meta: {
						title: 'Add New Broadcast',
						sidebar: true,
						crumbName: 'New Broadcast',
						dataRequired: true
					},
					component: () =>
						import('./views/app/newBroadcast'),
				},
				{
					path: '/edit/:id',
					meta: {
						title: 'Edit Broadcast',
						sidebar: true,
						crumbName: 'Edit Broadcast',
						dataRequired: true
					},
					component: () =>
						import('./views/app/newBroadcast'),
				},
				{
					path: '/resend/:id',
					meta: {
						title: 'Resend Broadcast',
						sidebar: true,
						crumbName: 'Resend Broadcast',
						dataRequired: true
					},
					component: () =>
						import('./views/app/newBroadcast'),
				},
				// 404
				{
					path: '/404',
					meta: {
						title: 'Error 404',
					},
					component: () =>
						import('./views/system/404'),
				},
				{
					path: "/notifications",
					meta: {
						title: "All Notifications",
						sidebar: true,
						dataRequired: true
					},
					component: () => import('bh-mod/components/layout/TopBar/Actions/notifications.vue'),
				},
			],
		},
		{
			path: '/settings',
			redirect: '/settings',
			component: AppLayout,
			meta: { instanceRequired: true, auth: true, hidden: true, },
			children: [{
				path: '/settings',
				meta: {
					title: 'SMS Settings',
					sidebar: true,
					crumbName: 'Settings',
					dataRequired: true
				},
				component: () =>
					import('./views/app/settings'),
			}]
		},
		{
			path: '/setup',
			component: Splash,
			meta: { hidden: true, splash: true },
			children: [{
				path: '/setup',
				meta: {
					title: 'Setup SMS & Broadcast',
					splashScreen: true
				},
				component: () =>
					import('./views/setup'),
			}]
		}
	],
})
router.afterEach(async () => {
	store.commit('LOAD', false)
})
router.beforeEach(async (to, from, next) => {
	store.commit('LOAD', true)
	let dataRequired = to.matched.some(record => record.meta.dataRequired)
	let splashScreen = to.matched.some(record => record.meta.splashScreen)
	let error
	if (!hiveRequested) {
		let resp = await VueAppMiddleware(to, store, 'sms')
		error = resp.error
		hiveRequested = true
	}

	store.commit('PAGE_BUTTONS', [])
	if (error) {
		return console.error('ERRORRRRR', error)
	} else {
		if (splashScreen && store.state.instance && store.state.instance.twilioApplicationToPersonData && (Object.keys(store.state.instance.twilioApplicationToPersonData).length) && store.state.sms.allSettings.userApp.options.seenIntro) {
			return next('/')
		} else if (dataRequired && (!store.state.instance || !store.state.instance.twilioApplicationToPersonData || !Object.keys(store.state.instance.twilioApplicationToPersonData).length) && !store.state.sms.getStarted && !store.state.sms.allSettings.userApp.options.seenIntro) {
			return next('/setup')
		}
	}

	if (to.path.startsWith('/new')) {
		let twilio = store.state.instance && store.state.instance.twilioApplicationToPersonData;
		let profile = twilio && twilio.twilioCustomerProfile;

		if (!profile || profile.status !== 'twilio-approved') {
			// return next('/settings')
		}
	}

	return next();
})
export default router
