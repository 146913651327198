<template>
	<SubBarGlobal
		:currentCrumb="$route.meta.crumbName ? false : $store.state.sms.selectedTab[0].toUpperCase() + $store.state.sms.selectedTab.substr(1)">
		<div class="page-buttons" v-if="$route.path === '/'">
			<a-tooltip v-if="disabledButton" overlayClassName="change-tooltip-color">
				<template slot="title">
					<span v-if="!profileStatus">
						Please first create your onboarding profile from <span @click="gotoSetting()"
							style="cursor: pointer; color: var(--primary)">here</span>
					</span>
					<span v-else-if="!isProfileApproved">
						Your Profile is not yet verified. Please wait for few days or contact administrator.
					</span>
				</template>
				<a-button type="primary" icon="plus" @click="() => { }" :disabled="disabledButton" size="large">
					CREATE BROADCAST
				</a-button>
			</a-tooltip>
			<a-button v-else type="primary" icon="plus" @click="newBroadcast" size="large">
				CREATE BROADCAST
			</a-button>
		</div>
	</SubBarGlobal>
</template>

<script>
import SubBarGlobal from 'bh-mod/components/layout/SubBarGlobal'

export default {
	components: { SubBarGlobal },
	data() {
		return {}
	},
	computed: {
		pageButtons() {
			return this.$store.state.pageButtons
		},
		instance() {
			return this.$store.state.instance
		},
		disabledButton() {
			return !this.profileStatus || !this.isProfileApproved;
		},
		profileStatus() {
			return this.instance && this.instance.twilioApplicationToPersonData && this.instance.twilioApplicationToPersonData.twilioCustomerProfile && this.instance.twilioApplicationToPersonData.twilioCustomerProfile.status;
		},
		isProfileApproved() {
			return this.profileStatus === 'twilio-approved'
		},
		activePhoneNumbers() {
			return this.$store.state.sms.activePhoneNumbers
		}
	},
	methods: {
		newBroadcast() {
			if (this.activePhoneNumbers && this.activePhoneNumbers.length > 0) {
				this.$router.push("/new");
			} else {
				this.$message.warning(`You have no active phone numbers. Please rent a new phone number from settings section.`)
			}
		},
		gotoSetting() {
			this.$router.push("/settings");
		}
	},
	created() {

	}
}
</script>

<style lang="scss">
</style>
