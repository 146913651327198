import Vue from 'vue'
import Vuex from 'vuex'

Vue.use(Vuex)

export default {
	state: {
		mediaSelect: {
			title: '',
			show: false,
			callback: null,
			type: 'any'
		},
		librarySelect: {
			title: '',
			show: false,
			callback: null,
			type: 'any'
		},
	},
	mutations: {
		CLOSE_LIBRARY: state => {
			state.mediaSelect = {
				title: '',
				show: false,
				callback: null,
				type: 'any'
			}
			state.librarySelect = {
				title: '',
				show: false,
				callback: null,
				type: 'any'
			}
		},
		MEDIA_SELECT: (state, { callback, type = 'all', title = 'Select a File...' }) => {
			state.mediaSelect.show = false

			state.mediaSelect = {
				show: true,
				callback,
				type,
				title
			}
		},
		LIBRARY_SELECT: (state, { callback, type = 'all', title = 'Select from the Library...' }) => {
			state.mediaSelect.show = false
			state.mediaSelect = {
				show: true,
				callback,
				type,
				title
			}
		},
		UPDATE_USER(state, user) {
			state.user = user
		},
		UPDATE_INVITATIONS(state, data) {
			state.invites = data
		},
		ACCEPT_INVITE: (state, invite) => {
			state.invites = state.invites.filter(x => x.id !== invite.id)
		},
	},
	actions: {},

}
